
(function ($) {

    var ieVersion = (function() { if (new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})").exec(navigator.userAgent) != null) { return parseFloat( RegExp.$1 ); } else { return false; } })();
    var isIE = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;
    if(ieVersion || isIE){
      var ieVersionclass = 'ie' + ieVersion;
      $('html').addClass(ieVersionclass).addClass('iexplorer');
    } 



    var App = {
    	scrolldown: function(){
			$('a.scroll').click(function(e) {
                e.preventDefault();
			    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') || location.hostname == this.hostname) {
			        var target = $(this.hash);
			        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			           if (target.length) {
                        if($(window).width() > 850){
			             $('html,body').animate({
			                 scrollTop: target.offset().top
			             }, 700);
			             return false;
                        } else{
                         $('html,body').animate({
                             scrollTop: target.offset().top -104
                         }, 700);
                         return false;
                        }


			           }
			    }
			});

    	},
        menuMobile: function () {

                function hasParent(e, p) {
                    if (!e) { return false; }
                    var el = e.target || e.srcElement || e || false;
                    while (el && el !== p) {
                        el = el.parentNode || false;
                    }
                    return (el !== false);
                }


                var triggerBttn = document.querySelector('.menu-button'),
                menucontainer = document.querySelector('div.mb-menu'),
                header = document.querySelector('header.banner'),
                isMenuOpen = false;

                function toggleMenu() {
                    if (isMenuOpen) {
                        classie.remove(menucontainer, 'opened');
                        classie.remove(triggerBttn, 'menu-button--open');
                        
                        $('html').css('overflow-y', 'scroll');
                        $('body').unbind('touchmove');




                    }
                    else {
                        classie.add(menucontainer, 'opened');
                        classie.add(triggerBttn, 'menu-button--open');
                        $('html').css('overflow', 'hidden');
                        $('body').bind('touchmove', function (e) {
                            e.preventDefault();
                        });

                    }
                    $('.navbar-header').toggleClass('hide');
                    $('.nav-menu').toggleClass('opened');
                    $('a.logo').toggleClass('hide');
                    isMenuOpen = !isMenuOpen;
                }

                function initEventsMenu() {
                    triggerBttn.addEventListener('click', toggleMenu);
                        $('a.nav-link-mobile').click(function(){
                                toggleMenu();
                        });
                }

                function init() {
                    initEventsMenu();
                }



                function closeMenus() {
                    toggleMenu();
                    isMenuOpen = !isMenuOpen;
                }

                init();


        },
        tabsNav: function(){

            var owl = $('.slider-story');
            owl.owlCarousel({
                items:1,
                lazyLoad:true,
                autoHeight:true,
                loop:true,
                autoplay:false,
                autoplayTimeout:4000,
                autoplayHoverPause:true,
                margin:10,
                responsive:{  
                    1200:{
                      autoplay:true,
                    }
                }

            });

            $('#tab-years a').click(function (e) {
              e.preventDefault();
              $(this).tab('show');
              owl.trigger('play.owl.autoplay',[1000]);
            });
        },
        timeline: function(){
            var timelineBlocks = $('.ag-timeline-block'),
                offset = 0.8;

            hideBlocks(timelineBlocks, offset);

            $(window).on('scroll', function(){
                (!window.requestAnimationFrame) ? setTimeout(function(){ showBlocks(timelineBlocks, offset); }, 100) : window.requestAnimationFrame(function(){ showBlocks(timelineBlocks, offset); });
            });

            function hideBlocks(blocks, offset) {
                blocks.each(function(){
                    ( $(this).offset().top > $(window).scrollTop()+$(window).height()*offset ) && $(this).find('.ag-timeline-img, .ag-timeline-content').addClass('is-hidden');
                });
            }

            function showBlocks(blocks, offset) {
                blocks.each(function(){
                    ( $(this).offset().top <= $(window).scrollTop()+$(window).height()*offset && $(this).find('.ag-timeline-img').hasClass('is-hidden') ) && $(this).find('.ag-timeline-img, .ag-timeline-content').removeClass('is-hidden').addClass('bounce-in');
                });
            }
        },
        photogallery: function(){
            var initPhotoSwipeFromDOM = function(gallerySelector) {

                // parse slide data (url, title, size ...) from DOM elements 
                // (children of gallerySelector)
                var parseThumbnailElements = function(el) {
                    var thumbElements = el.childNodes,
                        numNodes = thumbElements.length,
                        items = [],
                        figureEl,
                        linkEl,
                        size,
                        item;

                    for(var i = 0; i < numNodes; i++) {

                        figureEl = thumbElements[i]; // <figure> element

                        // include only element nodes 
                        if(figureEl.nodeType !== 1) {
                            continue;
                        }

                        linkEl = figureEl.children[0]; // <a> element

                        size = linkEl.getAttribute('data-size').split('x');

                        // create slide object
                        item = {
                            src: linkEl.getAttribute('href'),
                            w: parseInt(size[0], 10),
                            h: parseInt(size[1], 10)
                        };



                        if(figureEl.children.length > 1) {
                            // <figcaption> content
                            item.title = figureEl.children[1].innerHTML; 
                        }

                        if(linkEl.children.length > 0) {
                            // <img> thumbnail element, retrieving thumbnail url
                            item.msrc = linkEl.children[0].getAttribute('src');
                        } 

                        item.el = figureEl; // save link to element for getThumbBoundsFn
                        items.push(item);
                    }

                    return items;
                };

                // find nearest parent element
                var closest = function closest(el, fn) {
                    return el && ( fn(el) ? el : closest(el.parentNode, fn) );
                };

                // triggers when user clicks on thumbnail
                var onThumbnailsClick = function(e) {
                    e = e || window.event;
                    e.preventDefault ? e.preventDefault() : e.returnValue = false;

                    var eTarget = e.target || e.srcElement;

                    // find root element of slide
                    var clickedListItem = closest(eTarget, function(el) {
                        return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
                    });

                    if(!clickedListItem) {
                        return;
                    }

                    // find index of clicked item by looping through all child nodes
                    // alternatively, you may define index via data- attribute
                    var clickedGallery = clickedListItem.parentNode,
                        childNodes = clickedListItem.parentNode.childNodes,
                        numChildNodes = childNodes.length,
                        nodeIndex = 0,
                        index;

                    for (var i = 0; i < numChildNodes; i++) {
                        if(childNodes[i].nodeType !== 1) { 
                            continue; 
                        }

                        if(childNodes[i] === clickedListItem) {
                            index = nodeIndex;
                            break;
                        }
                        nodeIndex++;
                    }



                    if(index >= 0) {
                        // open PhotoSwipe if valid index found
                        openPhotoSwipe( index, clickedGallery );
                    }
                    return false;
                };

                // parse picture index and gallery index from URL (#&pid=1&gid=2)
                var photoswipeParseHash = function() {
                    var hash = window.location.hash.substring(1),
                    params = {};

                    if(hash.length < 5) {
                        return params;
                    }

                    var vars = hash.split('&');
                    for (var i = 0; i < vars.length; i++) {
                        if(!vars[i]) {
                            continue;
                        }
                        var pair = vars[i].split('=');  
                        if(pair.length < 2) {
                            continue;
                        }           
                        params[pair[0]] = pair[1];
                    }

                    if(params.gid) {
                        params.gid = parseInt(params.gid, 10);
                    }

                    return params;
                };

                var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
                    var pswpElement = document.querySelectorAll('.pswp')[0],
                        gallery,
                        options,
                        items;

                    items = parseThumbnailElements(galleryElement);

                    // define options (if needed)
                    options = {

                        // define gallery index (for URL)
                        galleryUID: galleryElement.getAttribute('data-pswp-uid'),

                        getThumbBoundsFn: function(index) {
                            // See Options -> getThumbBoundsFn section of documentation for more info
                            var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                                pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                                rect = thumbnail.getBoundingClientRect(); 

                            return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
                        }

                    };

                    // PhotoSwipe opened from URL
                    if(fromURL) {
                        if(options.galleryPIDs) {
                            // parse real index when custom PIDs are used 
                            // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                            for(var j = 0; j < items.length; j++) {
                                if(items[j].pid == index) {
                                    options.index = j;
                                    break;
                                }
                            }
                        } else {
                            // in URL indexes start from 1
                            options.index = parseInt(index, 10) - 1;
                        }
                    } else {
                        options.index = parseInt(index, 10);
                    }

                    // exit if index not found
                    if( isNaN(options.index) ) {
                        return;
                    }

                    if(disableAnimation) {
                        options.showAnimationDuration = 0;
                    }

                    // Pass data to PhotoSwipe and initialize it
                    gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
                    gallery.init();
                };

                // loop through all gallery elements and bind events
                var galleryElements = document.querySelectorAll( gallerySelector );

                for(var i = 0, l = galleryElements.length; i < l; i++) {
                    galleryElements[i].setAttribute('data-pswp-uid', i+1);
                    galleryElements[i].onclick = onThumbnailsClick;
                }

                // Parse URL and open gallery if it contains #&pid=3&gid=1
                var hashData = photoswipeParseHash();
                if(hashData.pid && hashData.gid) {
                    openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
                }
            };

            // execute above function
            initPhotoSwipeFromDOM('.slider-story');
        },
        mapLoad: function(){

            function new_map( $el ) {
                
                var $markers = $el.find('.marker');
                
                var args = {
                    zoom        : 11,
                    center      : new google.maps.LatLng(0, 0),
                    mapTypeId   : google.maps.MapTypeId.ROADMAP,
                    mapTypeControl: false,
                    scrollwheel: false,
                    styles: [{"featureType":"all","elementType":"geometry.fill","stylers":[{"weight":"2.00"}]},{"featureType":"all","elementType":"geometry.stroke","stylers":[{"color":"#9c9c9c"}]},{"featureType":"all","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape.man_made","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"color":"#eeeeee"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#7b7b7b"}]},{"featureType":"road","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#46bcec"},{"visibility":"on"}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#c8d7d4"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#070707"}]},{"featureType":"water","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]}]
                };
                
                
                var map = new google.maps.Map( $el[0], args);
                
                map.markers = [];
                
                $markers.each(function(){
                    add_marker( $(this), map );
                });
                
                center_map( map );
        
                return map;
                
            }

            function add_marker( $marker, map ) {

                var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
                var imgmarker = $marker.attr('data-img');
                var marker = new google.maps.Marker({
                    position    : latlng,
                    map         : map,
                    icon: imgmarker
                });
                map.markers.push( marker );


            }

            function center_map( map ) {
                var bounds = new google.maps.LatLngBounds();
                $.each( map.markers, function( i, marker ){
                    var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
                    bounds.extend( latlng );
                });

                if( map.markers.length == 1 )
                {
                    map.setCenter( bounds.getCenter() );
                    map.setZoom( 8 );
                }
                else
                {
                    map.fitBounds( bounds );
                }

            }

            var map = null;

            $('.map-ag').each(function(){
                map = new_map( $(this) );
            });
        },
    };
    var UTIL = {
        loadEvents: function () {
            App.scrolldown();
            App.tabsNav();
            App.timeline();
            App.mapLoad();
            App.photogallery();
            App.menuMobile();
            $('.fade-in').addClass('show');
        }
    };
    $(document).ready(UTIL.loadEvents);
    $( window ).resize(function() {
    });

})(jQuery);